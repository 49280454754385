<template>
	<div>
		<h3 class="focus size-px-16 color-333">STAKING 내역</h3>
		<div class="mt-10">
			<div
				v-if="items.length > 0"
			>
				<table
					class="table"
				>
					<colgroup>
						<col style="width: 25%">
						<col style="width: 25%">
						<col style="width: 25%">
						<col style="width: 25%">
					</colgroup>
					<thead>
						<tr>
							<th>일시</th>
							<th>Mining</th>
							<th>일시</th>
							<th>Mining</th>
						</tr>
					</thead>
				</table>
				<ul>
					<li
						v-for="(item, index) in items"
						:key="'item_' + index"
						class="inline-block width-50 under-line box-staking-history"
					>
						<div class="flex-row justify-space-between pt-20 pb-20">
							<div class="flex-1 text-center size-px-14 color-333">{{ item.reward_daytime }}</div>
							<div class="flex-1 text-center size-px-14 color-333">{{  item.reward_token_quantity }}</div>
						</div>
					</li>
					<li
						v-if="items.length % 2 != 0"
						class="inline-block width-50 under-line "
					>
						<div class="flex-row justify-space-between pa-20 color-white">1
						</div>
					</li>
				</ul>

				<Pagination
					v-if="items.length > 0"
					:options="item_search"
					@click="getSearchData"

					class="mt-30"
				></Pagination>
			</div>
			<div
				v-else
				class="mt-30 none"
			>스테이킹 이력이 없습니다</div>
		</div>
	</div>
</template>

<script>
 import Pagination from "@/components/Pagination";
export default {
	name: 'mafia038'
	, props: ['user']
	, components: { Pagination }
	, data: function(){
		return {
			program: {
				name: 'STAKING 내역'
			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, total_count: 0
			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_staking_history_info
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.stakg_nft_card_reward_list
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getDataSize: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_staking_history_info
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if(result.success){
					if(result.data.stakg_nft_card_reward_list.length > 0){
						this.item_search.total_count += result.data.stakg_nft_card_reward_list.length
						this.item_search.page++
						await this.getDataSize()
					}else{
						await this.getSearchData(1)
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearchData: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getDataSize()
	}
}
</script>

<style>
	.box-staking-history:not(:nth-child(2n)) {
		border-right: 1px solid #ddd; box-sizing: border-box
	}
</style>